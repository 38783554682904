<template>
  <div style="background: #f4f4f4;">
    <van-list
        v-model="tableLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :key="timer"
    >
      <api-order-item v-for="item in tableData" :item="item"/>
    </van-list>

    <div class="fixed_right_menu">
      <div class="item" @click="fallbackZnkf">
        &lt;返回
      </div>
    </div>
  </div>
</template>

<script>
import apiOrderItem from './apiOrderItem.vue';

export default {
  components: {apiOrderItem},
  name: "ApiOrderList",
  props: {
    orderType: {},
    query: {
      type: Object,
      "default": {},
    }
  },
  data() {
    return {
      loading: false,
      type: null,
      sort: null,
      list: [],
      timer: '',
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5 // 每页显示多少条
      },
      tableLoading: false,
      refreshing: false,
      finished: false,
      //加载数据
      tableData: [],
    };
  },
  filters: {
    orderType(val) {
      switch (val) {
        case "1":
          return "等待买家付款";
        case "2":
          return "生产中";
        case "3":
          return "配送中";
        case "4":
          return "部分发货";
        case "5":
          return "已签收";
        case "6":
          return "退款成功";
        case "7":
          return "取消订单";
      }
    },
  },
  methods: {
    //分页
    getList(page, params) {
      this.tableLoading = true
      this.$http.get(this.baseMallUrl + "/h5_mall_order/apiOrderPage", {
        params: Object.assign({
          current: page.currentPage,
          size: page.pageSize
        }, params)
      }, {
        emulateJSON: true
      }).then(response => {
        if (page.currentPage == 1) {
          this.tableData = response.data.records
        } else {
          this.tableData = this.tableData.concat(response.data.records)
        }
        this.page.total = response.data.total
        if (this.tableData.length >= this.page.total) {
          this.finished = true;
        } else {
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
        // this.finished = true;
      })
    },
    // 截取code
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
    onLoad() {
      let query = this.query;
      query.type = this.type;
      query.sort = this.sort;
      this.getList(this.page, query)
    },
    fallbackZnkf(){
      window.history.go(-3);
    }
  },
  mounted() {
    this.page = {
      total: 0, // 总页数
      currentPage: 1, // 当前页数
      pageSize: 5 // 每页显示多少条
    };
    this.sort = this.GetParam(window.location.href, "sort");
    this.type = this.GetParam(window.location.href, "type");
    this.tableData = [];
    this.finished = false;
  },
  watch: {
    orderType: {
      handler() {
        this.tableData = []
        this.page = {
          total: 0, // 总页数
          currentPage: 1, // 当前页数
          pageSize: 5 // 每页显示多少条
        };
        this.finished = false
        this.onLoad()
      }
    }
  },
};
</script>
<style lang="less" scoped>
.fixed_right_menu {
  position: fixed;
  right: 2px;
  top: 35px;
  z-index: 5;

  .item {
    width: 60px;
    height: 30px;
    background: #FEFEFE;
    box-shadow: 0px 1px 5px 0px rgba(177, 177, 177, 0.3000);
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    color: #71767c;

    img.dh {
      width: 19px;
      height: 21px;
    }

    img.kf {
      height: 24px;
      width: 23px;
    }

    img.gd {
      height: 11px;
      width: 20px;
    }
  }
}
</style>
